import React, { useState } from "react"
import Sticky from "react-sticky-el"
import Logo from "../images/logo_airro.png"
import Css from "./SiteHeader.module.scss"
import { Link } from "gatsby"

const SiteHeader = props => {
  let data = props.content.menu
  //   let buttonLabel = props.content.label
  let items = Object.keys(data)
  let color = props.bgColor ? props.bgColor : "transparent"
  let style = {
    background: color,
  }

  const [showMenu, setShowMenu] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)

  const onHamburguerClicked = () => {
    if (showSubMenu) {
      setShowMenu(false)
      setShowSubMenu(false)
    } else {
      setShowMenu(!showMenu)
    }
  }

  const subMenuClicked = () => {
    setShowSubMenu(!showSubMenu)
  }

  const menuItems = items.map(item => {
    let menuItem = null

    if (data[item].items) {
      let subItems = data[item].items.map((subItem, index) => {
        return (
          <Link
            key={index}
            to={"/" + subItem.url}
            className={Css.ContainerItem}
          >
            <div className={Css.SubMenuTitle}>{subItem.title}</div>
            <div className={Css.SubMenuDescription}>{subItem.description} </div>
          </Link>
        )
      })

      menuItem = (
        <div
          className={Css.Work}
          onClick={subMenuClicked}
          role="button"
          aria-hidden
        >
          <div className={showSubMenu ? Css.LabelActive : Css.Label}>
            {data[item].name}
          </div>
          <div
            className={Css.SubMenu}
            style={{ display: showSubMenu ? "flex" : "none" }}
          >
            <div
              className={Css.BackBtn}
              onClick={subMenuClicked}
              role="button"
              aria-hidden
            >
              Work
            </div>
            {subItems}
          </div>
        </div>
      )
    } else {
      menuItem = <Link to={"/" + item}>{data[item]}</Link>
    }

    const selected = item === props.page ? Css.MenuItemSelected : ""
    return (
      <div key={item} className={[Css.MenuItem, selected].join(" ")}>
        {menuItem}
      </div>
    )
  })

  return (
    <Sticky topOffset={0} className={Css.FadeOut} stickyClassName={Css.Sticky}>
      <div className={Css.Header} style={style}>
        <Link to="/">
          <img
            src={Logo}
            className={[
              showMenu ? Css.Logo : Css.Logo,
              showSubMenu ? Css.LogoHidden : Css.Logo,
            ].join(" ")}
            alt="Airro Logo"
          />
        </Link>
        <div
          onClick={onHamburguerClicked}
          className={[Css.navIcon, showMenu ? Css.CrossActive : ""].join(" ")}
          role="button"
          aria-hidden
        >
          <div className={Css.Span}></div>
        </div>

        <div
          className={[
            showMenu ? Css.MenuMobileOpened : Css.MenuMobileClosed,
            showSubMenu ? Css.MenuMobileToLeft : Css.MenuMobileCenter,
          ].join(" ")}
        >
          {menuItems}
        </div>
        <div className={Css.Menu}> {menuItems} </div>
      </div>
    </Sticky>
  )
}

export default SiteHeader
