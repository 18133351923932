import $ from "jquery"

const animTime = 2000
const delayTime = 5000

export default function carousel(maxItems, id) {
  if (!id) {
    id = 0
  }
  if (id >= maxItems) {
    //Reset the cycle
    let lastIdx = maxItems - 1
    for (let i = 1; i < lastIdx; i++) {
      let el = $(document.getElementById("#hero" + i))
      el.css("opacity", 0.0);
    }
    let el = $(document.getElementById("#hero" + lastIdx))
    el.delay(delayTime).animate({ "opacity": 0.0 }, animTime, () => {
      carousel(maxItems, 0)
    })
  } else {
    let el = $(document.getElementById("#hero" + id))
    let obj = (id === 0) ? el : el.delay(delayTime)
    obj.animate({ "opacity": 1.0 }, animTime, () => carousel(maxItems, ++id))
  }

}