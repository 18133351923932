import React from 'react';
import LogoBlack from "../images/logo_airro_footer.png"
import LogoWhite from "../images/logo_airro_footer_white.png"
import Css from "./SiteFooter.module.css"
import { Link } from "gatsby"



const SiteFooter = props => {

  let data = props.content
  let items = Object.keys(data)

  const menuItems = items.map(item => {
    return <div key={item} className={Css.MenuItem}>
      <Link to={'/'+item} >{data[item]}</Link>
    </div>
  })
 
  return (
    <div className={Css.Footer}>
      <Link to="/">
        <img src={LogoBlack} className={Css.LogoBlack} alt="Airro Logo" />
      </Link>
      <div className={Css.Filler} > &nbsp; </div>
      <div className={Css.Menu}> {menuItems} </div >
      <div className={Css.Filler2} > &nbsp; </div>
      <Link to="/">
        <img src={LogoWhite} className={Css.LogoWhite} alt="Airro Logo" />
      </Link>
    </div >
  )
}

export default SiteFooter