import React, {useEffect} from "react"
import Css from "./TopImageContainer.module.css"
import carousel from '../helpers/Carousel'

const TopImageContainer = props => {
  const imagestyle = {
    backgroundColor: "#ffffff",
    padding: "0px",
    margin: "0px"
  };

  if (props.image) {
    imagestyle.background = ` url('${props.image}') no-repeat center center`
    imagestyle.backgroundSize = 'cover'
    
  } 
  const image = props.image
  useEffect(() => {
    const MAX_CAROUSEL = 4
    if (!image) {
      carousel(MAX_CAROUSEL)
    }
  }, [image])

  return <div style={imagestyle} > 
        { !props.image &&
          <div className={Css.Carousel} >
            <div id="#hero0" className={[Css.HeroImg, Css.Hero1].join(' ')} />
            <div id="#hero1" className={[Css.HeroImg, Css.Hero2].join(' ')} />
            <div id="#hero2" className={[Css.HeroImg, Css.Hero3].join(' ')} />
            <div id="#hero3" className={[Css.HeroImg, Css.Hero4].join(' ')} />
            <div className={Css.HeroOverlay}>&nbsp;</div>
          </div>
        }
        {props.children} 
      </div>
}

export default TopImageContainer